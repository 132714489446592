
import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import axiosConfig from '../../utils/axiosConfig';
import { svgIcons, svgImgs } from '../../utils/svgs';
import { getCurrentAppType, fireClickstreamEvent, validate, sanitizeString } from '../../utils/utilities';
import { logCSPageLoad } from '../../utils/tracking';
import { apiUri, properties } from '../../utils/properties';
import ForgotPasswordMeta from './ForgotPasswordMeta';
import "./ForgotPassword.css";
import styled from 'styled-components';
import $ from "jquery";

const ErrorMessage = loadable(() => import('../../components/ErrorMessage/ErrorMessage'));
const SimpleButton = loadable(() => import('../../components/SimpleButton/SimpleButton'));

export const emailItem = {
    id: 'input-container-email',
    inputId: 'input_email',
    // description: 'This may be your email address.',
    type: 'text',
    ariaLabel: 'Email address',
    currentValue: '',
    helperId: 'email_helper',
    emptyErrorMessage: 'Required',
    errorMsg: ''
}

export const dobItem = {
    id: 'input-container-dob',
    inputId: 'input_dob',
    // description: 'This may be your email address.',
    type: 'text',
    ariaLabel: 'Date of birth',
    currentValue: '',
    helperId: 'dob_helper',
    emptyErrorMessage: 'Required',
    description: `Enter the date in "MMDDYYYY" format.`,
    // description: `Example: Enter 0101${new Date().getFullYear() - 18} for 01/01/${new Date().getFullYear() - 18}`,
    errorMsg: ''
}

export const ssnItem = {
    id: 'input-container-ssn',
    inputId: 'input_ssn',
    // description: 'This may be your email address.',
    type: 'text',
    ariaLabel: 'Social Security number',
    currentValue: '',
    helperId: 'ssn_helper',
    emptyErrorMessage: 'Required',
    description: 'Enter SSN without dashes (-)',
    errorMsg: ''
}

export const getEmailFromInput = () => {
    return document.getElementById(emailItem.inputId) ? document.getElementById(emailItem.inputId).value : "";
}
export const getEmailErrorMsg = () => {
    return document.getElementById(`${emailItem.helperId}-err`) ? document.getElementById(`${emailItem.helperId}-err`).innerText : "";
}
export const getDOBFromInput = () => {
    return document.getElementById(dobItem.inputId) ? document.getElementById(dobItem.inputId).value : "";
}
export const getDOBErrorMsg = () => {
    return document.getElementById(`${dobItem.helperId}-err`) ? document.getElementById(`${dobItem.helperId}-err`).innerText : "";
}
export const getSSNFromInput = () => {
    return document.getElementById(ssnItem.inputId) ? document.getElementById(ssnItem.inputId).value : "";
}
export const getSSNErrorMsg = () => {
    return document.getElementById(`${ssnItem.helperId}-err`) ? document.getElementById(`${ssnItem.helperId}-err`).innerText : "";
}

const ForgotPassword = (props) => {

    if (window.useNewForgotFlow && window.useNewForgotFlow === "false") {
        window.location.replace("")
    }
    // const dispatch = useDispatch();
    const resetUri = apiUri.pwReset;
    const emailRef = useRef();
    const dobRef = useRef();
    const ssnRef = useRef();

    const { location } = props;
    const [acctInfo, setAcctInfo] = useState({
        requestStatus: ""
    });
    const [authStep, setAuthStep] = useState((typeof window.useNewForgotFlow !== "undefined" && window.useNewForgotFlow === "false") ? null : 1);
    const [emailInput, setEmailInput] = useState(emailItem)
    const [dobInput, setDobInput] = useState(dobItem)
    const [ssnInput, setSsnInput] = useState(ssnItem)
    const slashRegex = /\//ig;
    const dashRegex = /-/ig;

    const [btnEnabled, setBtnEnabled] = useState(true);

    useEffect(() => {
        if (props.updateState) {
            const setState = (updateStateName, updateStateValue) => {
                switch (updateStateName) {
                    case "authStep": setAuthStep(updateStateValue); break;
                    case "acctInfo": setAcctInfo(updateStateValue); break;
                    default: return false;
                }
            }

            Object.keys(props.updateState).map((item) => {
                setState(item, props.updateState[item]);
                return true;
            })
        }
    }, [props.updateState])

    const skipStepHandler = () => {
        setAuthStep(2);
        fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.step.2' });
        $("#reset-step").focus();
    }

    const nextStepHandler = () => {
        const inputValue = emailRef.current.value;
        setBtnEnabled(false);
        if (!inputValue) {
            setBtnEnabled(true);
            setEmailInput({ ...emailInput, errorMsg: 'Email is required' })
            document.getElementById(emailInput.inputId).focus();
        } else {
            setEmailInput({ ...emailInput, errorMsg: '' });

            const isValidEmail = validate.email(inputValue);
            if (isValidEmail && typeof isValidEmail === "boolean") {

                const data = {
                    "email": inputValue
                }
                let finalResetUrl = resetUri;
                if (acctInfo && acctInfo.product_id) {
                    finalResetUrl += "?pid=" + acctInfo.product_id;
                }

                if (acctInfo && acctInfo.vt) {
                    if (finalResetUrl.indexOf("?") > -1) {
                        finalResetUrl += "&vt=" + acctInfo.vt;
                    } else {
                        finalResetUrl += "?vt=" + acctInfo.vt;
                    }
                }

                axiosConfig.post(finalResetUrl, data)
                    .then(function (response) {
                        // handle success
                        // const res = response.data;
                        setAuthStep("success");
                        fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.success' });
                    })
                    .catch(function (err) {
                        if (err && err.response && err.response.data) {
                            const errData = err.response.data;
                            if (errData && errData.error) {
                                if (errData.error.code && (errData.error.code === "IDVerificationRequired" || errData.error.code === "UseIDVerification")) {
                                    if (errData.error.code === "IDVerificationRequired") {
                                        setAcctInfo({ ...acctInfo, email: inputValue })
                                    }
                                    setAuthStep(2);
                                    fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.step.2' });
                                    $("#reset-step").focus();
                                } else if (errData.error.code && errData.error.code === "InvalidRequestData") {
                                    setEmailInput({ ...emailInput, errorMsg: 'Invalid email address. Please enter your email address again.' })
                                    document.getElementById(emailInput.inputId).focus();
                                } else {
                                    if (errData.error.message && errData.error.message.toLowerCase().indexOf("locked") > -1) {
                                        setAcctInfo({ ...acctInfo, requestStatus: "AccountLocked" });
                                        fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.failed', category: "account.locked" });
                                    } else {
                                        fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.failed', category: "general" });
                                    }
                                    setAuthStep("failed");
                                }
                            }
                        }
                    })
                    .then(function () {
                        setBtnEnabled(true);
                    });



            } else {
                setBtnEnabled(true);
                setEmailInput({ ...emailInput, errorMsg: isValidEmail });
                document.getElementById(emailInput.inputId).focus();
            }
        }
    }
    const resetPassHandler = () => {
        setBtnEnabled(false);
        let inputValueDob = dobRef.current.value;
        let inputValueSsn = ssnRef.current.value;

        let isValidDob = validate.dob(inputValueDob);
        let isValidSsn = validate.ssn(inputValueSsn);

        if (isValidSsn) {
            const errorMsg = isValidSsn === true ? "" : isValidSsn;
            setSsnInput({ ...ssnInput, errorMsg: errorMsg });
            if (errorMsg) {
                document.getElementById(ssnInput.inputId).focus()
            }

        }

        if (isValidDob) {
            const errorMsg = isValidDob === true ? "" : isValidDob;
            setDobInput({ ...dobInput, errorMsg: errorMsg });
            if (errorMsg) {
                document.getElementById(dobInput.inputId).focus()
            }
        }

        if (isValidDob === true && isValidSsn === true) {
            if (inputValueDob.indexOf("/") > -1) {
                inputValueDob = inputValueDob.replace(slashRegex, "-")
            } else {
                if (inputValueDob.indexOf("/") === -1 && inputValueDob.length === 8) {
                    inputValueDob = `${inputValueDob.substring(0, 2)}/${inputValueDob.substring(2, 4)}/${inputValueDob.substring(4)}`;
                }
            }
            if (inputValueSsn.indexOf("-") > -1) {
                inputValueSsn = inputValueSsn.replace(dashRegex, "")
            }
            const data = {
                "dob": inputValueDob,
                "ssn": inputValueSsn
            }

            if (acctInfo && acctInfo.email) {
                data.email = acctInfo.email;
            }

            let finalResetUrl = resetUri;
            if (acctInfo && acctInfo.product_id) {
                finalResetUrl += "?pid=" + acctInfo.product_id;
            }
            if (acctInfo && acctInfo.vt) {
                if (finalResetUrl.indexOf("?") > -1) {
                    finalResetUrl += "&pid=" + acctInfo.vt;
                } else {
                    finalResetUrl += "?vt=" + acctInfo.vt;
                }
            }

            axiosConfig.post(finalResetUrl, data)
                .then(function (response) {
                    // handle success
                    // const res = response.data;
                    setAuthStep("success");
                    fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.success' });
                    $("#request-submitted").focus();
                })
                .catch(function (err) {
                    if (err && err.response && err.response.data) {
                        const errData = err.response.data;
                        if (errData && errData.error) {
                            if (errData.error.message && errData.error.message.toLowerCase().indexOf("locked") > -1) {
                                setAcctInfo({ requestStatus: "AccountLocked" });
                                fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.failed', category: "account.locked" });
                            } else {
                                fireClickstreamEvent({ 'type': 'display', 'name': 'forgot.request.failed', category: "general" });
                            }
                            setAuthStep("failed");
                            $("#request-submitted").focus();
                        }
                    }
                }).then(function () {
                    setBtnEnabled(true);
                })
        } else {
            setBtnEnabled(true);
        }

    }

    const onEmailBlurHandler = () => {
        let inputValue = emailRef.current.value;
        if (inputValue === "") {
            setEmailInput({ ...emailInput, errorMsg: 'Email is required' })
        } else {
            const isValidEmail = validate.email(inputValue);
            const errorMsg = isValidEmail === true ? "" : isValidEmail;
            setEmailInput({ ...emailInput, errorMsg: errorMsg });
        }


    }

    const dobOnBlurHandler = () => {
        let inputValue = dobRef.current.value;
        if (inputValue.indexOf("/") === -1 && inputValue.length === 8) {
            inputValue = `${inputValue.substring(0, 2)}/${inputValue.substring(2, 4)}/${inputValue.substring(4)}`;
            document.getElementById(dobInput.inputId).value = inputValue;
        }
        let isValidDob = validate.dob(inputValue);
        if (isValidDob) {
            const errorMsg = isValidDob === true ? "" : isValidDob;
            setDobInput({ ...dobInput, errorMsg: errorMsg });
            // if (errorMsg) {
            //     document.getElementById(dobInput.inputId).focus()
            // }
        }
    }
    const dobOnFocusHandler = () => {
        let inputValue = dobRef.current.value;
        let isValidDob = validate.dob(inputValue);
        if (isValidDob) {
            if (inputValue.indexOf("/") > -1) {
                inputValue = inputValue.replace(slashRegex, "")
            }
            document.getElementById(dobInput.inputId).value = inputValue;
        }
    }
    const ssnOnBlurHandler = () => {
        let inputValue = ssnRef.current.value;

        if (inputValue.indexOf("-") === -1 && inputValue.length === 9) {
            inputValue = `${inputValue.substring(0, 3)}-${inputValue.substring(3, 5)}-${inputValue.substring(5)}`;
            document.getElementById(ssnInput.inputId).value = inputValue;
        }
        let isValidSsn = validate.ssn(inputValue);
        if (isValidSsn) {
            const errorMsg = isValidSsn === true ? "" : isValidSsn;
            setSsnInput({ ...ssnInput, errorMsg: errorMsg });
        }
    }
    const ssnOnFocusHandler = () => {
        let inputValue = ssnRef.current.value;
        let isValidSsn = validate.ssn(inputValue);
        if (isValidSsn) {
            if (inputValue.indexOf("-") > -1) {
                inputValue = inputValue.replace(dashRegex, "")
            }
            document.getElementById(ssnInput.inputId).value = inputValue
        }
    }

    const nextStepButton = {
        id: 'forgot-info-next',
        buttonData: 'text',
        csName: 'forgot.info.next.button',
        text: 'Next',
        action: nextStepHandler,
        ariaLabel: 'continue to next step'
    }
    const skipStepButton = {
        id: 'forgot-info-skip',
        buttonData: 'text',
        csName: 'forgot.info.skip.button',
        text: 'click here',
        action: skipStepHandler,
        ariaLabel: 'skip email step to search by ssn',
        type: "text"
    }
    const resetPassButton = {
        id: 'forgot-info-reset',
        buttonData: 'text',
        csName: 'forgot.info.reset.button',
        text: 'Reset Password',
        action: resetPassHandler,
        ariaLabel: 'click to submit password reset',
    }

    useEffect(() => {
        let isLocationCall = true;

        if (typeof location !== "undefined" && isLocationCall) {
            const locPath = location.pathname;
            logCSPageLoad(locPath);
            let oldUrl = `${properties.webserver}/SystemAccess/ForgotMemberInfo.aspx`;
            const urlParams = new URLSearchParams(location.search);
            const acctInfoTemp = {};
            if (urlParams.has('pid')) {
                const purchasePid = sanitizeString(urlParams.get("pid"));
                if (purchasePid) {
                    acctInfoTemp.product_id = purchasePid
                }
            }

            if (urlParams.has('vt')) {
                const vtParam = sanitizeString(urlParams.get("vt"));
                if (vtParam) {
                    if (oldUrl.indexOf("?") > -1) {
                        oldUrl += "&vt=" + vtParam;
                    } else {
                        oldUrl += "?vt=" + vtParam;
                    }
                    acctInfoTemp.vt = vtParam
                }
            }

            if (Object.keys(acctInfoTemp).length > 0) {
                setAcctInfo({ ...acctInfo, ...acctInfoTemp })
            }

            if (typeof window.useNewForgotFlow !== "undefined" && window.useNewForgotFlow === "false") {
                window.location.replace(oldUrl);
            }
        }

        return () => {
            isLocationCall = false;
        }
    }, [location]);

    return (
        <React.Fragment>
            <ForgotPasswordMeta />

            <div className="bg auth-wrapper" >
                {authStep && authStep === 1 &&
                    <div id="forgot-info-step-1" className="auth-module" data-testid="forgot-info-step-1">
                        <h1>Reset Password</h1>
                        <h2 className="sr-only">Enter email address</h2>
                        <p>Please provide us with your email address to help us identify your account.</p>
                        <p>Note: 2-Step Verification will be turned off when you reset your password. Please remember to turn it on again.</p>
                        <ErrorMessage apiResError={acctInfo && acctInfo.apiResError} />
                        <div data-component="Input" data-type="TextField" id={emailInput.id} data-valid={(emailInput && emailInput.errorMsg) ? false : true}>
                            <input data-testid={emailInput.inputId} ref={emailRef} onBlur={onEmailBlurHandler} type={emailInput.type} id={emailInput.inputId} aria-label={emailInput.ariaLabel} aria-describedby={emailInput.helperId} placeholder=" " required aria-required="true" aria-invalid={(emailInput && emailInput.errorMsg) ? true : false} />
                            <div data-testid={`${emailInput.inputId}-label`} className="input-label" aria-hidden="true">{emailInput.ariaLabel}</div>

                            <div className="input-helper-container" >
                                <div data-testid={emailInput.helperId} className="description" id={emailInput.helperId}>{emailInput.description}</div>
                                <div className="error-message" aria-live="polite">
                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                    <span id={`${emailInput.helperId}-err`} data-testid={`${emailInput.helperId}-err`} className="text-container">{emailInput.errorMsg}</span>
                                </div>
                            </div>
                        </div>
                        <div className="full-width-btn">
                            <SimpleButton {...nextStepButton} ariaLive="off"  {...(btnEnabled) ? "" : { disabled: true }} />
                        </div>
                        <p className="skip-step">
                            <span>If you do not remember the email address for your account, </span><SimpleButton {...skipStepButton} ariaLive="off" /> to search by SSN.
                        </p>

                    </div>
                }

                {authStep && authStep === 2 &&
                    <div id="forgot-info-step-2" className="auth-module" data-testid="forgot-info-step-2">
                        <h1>Reset Password</h1>
                        <h2 id="reset-step" tabIndex="0" className="sr-only">Authenticate</h2>
                        <p aria-live="polite">Please provide us with the following information to help us identify your account.</p>
                        <ErrorMessage apiResError={acctInfo && acctInfo.apiResError} />
                        <div data-component="Input" data-type="TextField" id={dobInput.id} data-valid={(dobInput && dobInput.errorMsg) ? false : true}>
                            <input data-testid={dobInput.inputId} onBlur={dobOnBlurHandler} onFocus={dobOnFocusHandler} ref={dobRef} type={dobInput.type} id={dobInput.inputId} aria-label={dobInput.ariaLabel} aria-describedby={dobInput.helperId} placeholder=" " autoComplete="off" autoCapitalize="off" autoCorrect="off" required aria-required="true" aria-invalid={(dobInput && dobInput.errorMsg) ? true : false} />
                            <div data-testid={`${dobInput.inputId}-label`} className="input-label" aria-hidden="true">{dobInput.ariaLabel}</div>

                            <div className="input-helper-container">
                                <div data-testid={dobInput.helperId} className="description" id={dobInput.helperId}>{dobInput.description}</div>
                                <div className="error-message" aria-live="polite">
                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                    <span id={`${dobInput.helperId}-err`} data-testid={`${dobInput.helperId}-err`} className="text-container">{dobInput.errorMsg}</span>
                                </div>
                            </div>
                        </div>
                        <div data-component="Input" data-type="TextField" id={ssnInput.id} data-valid={(ssnInput && ssnInput.errorMsg) ? false : true}>
                            <input data-testid={ssnInput.inputId} onBlur={ssnOnBlurHandler} onFocus={ssnOnFocusHandler} ref={ssnRef} type={ssnInput.type} id={ssnInput.inputId} aria-label={ssnInput.ariaLabel} aria-describedby={ssnInput.helperId} aria-required="true" autoComplete="off" autoCapitalize="off" autoCorrect="off" placeholder=" " aria-invalid={(ssnInput && ssnInput.errorMsg) ? true : false} />
                            <div data-testid={`${ssnInput.inputId}-label`} className="input-label" aria-hidden="true">{ssnInput.ariaLabel}</div>

                            <div className="input-helper-container">
                                <div data-testid={ssnInput.helperId} className="description" id={ssnInput.helperId}>{ssnInput.description}</div>
                                <div className="error-message" aria-live="polite">
                                    <span className="icon-container"><svg aria-hidden="true" data-component="Icon" dangerouslySetInnerHTML={{ __html: svgIcons.error }}></svg></span>
                                    <span id={`${ssnInput.helperId}-err`} data-testid={`${ssnInput.helperId}-err`} className="text-container">{ssnInput.errorMsg}</span>
                                </div>
                            </div>
                        </div>
                        <div className="full-width-btn">
                            <SimpleButton {...resetPassButton} ariaLive="off" {...(btnEnabled) ? "" : { disabled: true }} />
                        </div>
                    </div>
                }

                {authStep && authStep === "success" &&
                    <div aria-live="polite" id="request-success" className="auth-module centerContent" data-testid="forgot-info-success">
                        <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.checkEmail }}></span>
                        <h2 tabIndex="0" className="sr-only" id="request-submitted">Request submitted</h2>
                        <p>We have received your request to reset your password. If you have an account with us, an email with instructions to reset your password has been sent to your email address.</p>
                    </div>
                }
                {authStep && authStep === "failed" &&
                    <div aria-live="polite" id="request-failed" className="auth-module centerContent" data-testid="forgot-info-failed">

                        {(acctInfo && acctInfo.requestStatus && acctInfo.requestStatus === "AccountLocked") ?
                            <React.Fragment>
                                <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.locked }}></span>
                                <h2 tabIndex="0" className="sr-only" id="request-submitted">Account locked.</h2>
                                <p>For your security, your account has been locked. Please contact us to unlock your account. You can also reach out to us using our <a className="cselem" data-cs-type="click" data-cs-name="failed.locked.contact.us" href="https://www.myfico.com/support/contactus?subject=Other">contact form</a>.</p>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <span aria-hidden="true" className="card-header-img" dangerouslySetInnerHTML={{ __html: svgImgs.emailNotFound }}></span>
                                <h2 tabIndex="0" className="sr-only" id="request-submitted">Please call us.</h2>
                                <p>Please contact myFICO Customer Support for help resetting your password. You can also reach out to us using our <a className="cselem" data-cs-type="click" data-cs-name="failed.contact.us" href="https://www.myfico.com/support/contactus?subject=Other">contact form</a>.</p>

                            </React.Fragment>
                        }
                        <p className="support-center-info">
                            <a className="info" href="tel:+18003194433"><span aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.phone }}></span>1-800-319-4433</a>
                        </p>
                        <p className="support-center-info">
                            <span className="info">
                                <span className="clock-icon" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgIcons.clock }}></span>
                                <span aria-hidden="true">Mon - Fri: 6 AM - 6 PM, Sat: 7 AM - 4 PM Pacific Time</span>
                                <span className="sr-only">myFICO Customer support opens from monday to friday from 6am to 6pm and saturday from 7am to 4pm pacific time.</span>
                            </span>
                        </p>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};

export default withRouter(ForgotPassword);