//Generic Utility Functions

import { localProp, properties } from "./properties";

export const sanitizeString = (str) => {
    if (str !== undefined && str !== null && str !== '') {
        str = str.replace(/[^a-z0-9 \.,:_-]/gim, "");
        return str.trim();
    }
    return;
}

export const fireClickstreamEvent = (csObject, redirUrl, newWin) => {
    if (typeof window === 'object' && typeof window.myfcs === 'object' && window.myfcs.hasOwnProperty('cs_setData')) {
        window.myfcs.cs_setData(csObject, redirUrl, newWin);
    } else {
        if (redirUrl) {
            window.location.href = redirUrl;
        }
    }
}

// generate options for select input fields
// pass array for startVal or a incrementable startVal with endVal
export const getOptions = (startVal, endVal, padNum) => {

    const options = [];
    if (typeof startVal == "object") {
        for (let i = 0; i < startVal.length; i++) {
            options.push(<option key={startVal[i]} value={startVal[i]}>{startVal[i]}</option>)
        }
    } else {
        for (let i = startVal; i <= endVal; i++) {
            let strVal = i;
            if (typeof padNum == "number" && padNum > 0) {
                strVal = '' + i;
                while (strVal.length < padNum) {
                    strVal = '0' + strVal;
                }
            }
            options.push(<option key={i} value={strVal}>{strVal}</option>)
        }
    }
    return options;
}

export const isAppTypeIOS = () => {
    let isIOSval = false;
    if (typeof window.apptype !== "undefined" && window.apptype !== null
        && (window.apptype === "ios" || window.apptype === "MYF_IPHONE")) {
        isIOSval = true;
    }
    return isIOSval;
}

export const isAppTypeAndroid = () => {
    let isAndroidval = false;
    if (typeof window.apptype !== "undefined" && window.apptype !== null
        && (window.apptype === "android" || window.apptype === "MYF_ANDROID")) {
        isAndroidval = true;
    }
    return isAndroidval;
}

export const isAppTypeMobile = () => {
    let isMobileval = false;
    if (isAppTypeIOS() || isAppTypeAndroid()) {
        isMobileval = true;
    }
    return isMobileval;
}

export const isBridgeActive = () => {
    if ((typeof window.isSwiftBridgeActiveV2 != 'undefined' && window.isSwiftBridgeActiveV2) || (typeof window.isSwiftBridgeActiveV3 != 'undefined' && window.isSwiftBridgeActiveV3) || (typeof window.isAndroidWebBridgeReadyV2 == 'function' && window.isAndroidWebBridgeReadyV2())) {
        return true;
    }
    return false;
}

//** For both iOS and Android EmbeddedView */
export const isAppEmbeddedView = () => {
    return isBridgeActive() && isAppTypeMobile();
}

export const isAppTypeFromQS = () => {
    let isMobileApptype = false;

    const appType = qsSearch().get('apptype');
    if (appType !== null && (appType === 'ios' || appType === 'android')) {
        isMobileApptype = true;
    }
    return isMobileApptype;
}

export const getCurrentAppType = () => {
    let apptype = "";

    if (localProp.testLocal) {
        apptype = "MYF_WEB"
    } else {
        if (typeof window.apptype !== "undefined" && window.apptype !== null) {
            apptype = window.apptype;
        }
    }
    return apptype;
}

export const getCurrentClientId = () => {
    let client = "";

    if (localProp.testLocal) {
        client = "123"
    } else {
        if (typeof window.myfcsObj !== "undefined" && window.myfcsObj !== null
            && typeof window.myfcsObj.clientId !== "undefined" && window.myfcsObj.clientId !== null) {
            client = window.myfcsObj.clientId;
        }
    }
    return client;
}

export const trimStr = (str) => {
    if (typeof str !== "undefined" && str !== null) {
        return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    }
    return "";
}

export const checkForReUrl = () => {
    let url = ''
    const qs = window.location.search || '';

    if (isFinra()) {
        if (qs !== "" && qs.toLowerCase().indexOf('returnurl') > -1) {
            url = encodeSSOservice(window.myfico.globalVars.returnUrl);
        } else {
            url = "https:" + properties.authServer + "/sso?service=https:" + properties.securewebserver + "/member/?finraLogin=true/#/scores";
        }
    }
    else if (typeof window.myfico !== "undefined" && window.myfico !== null
        && typeof window.myfico.globalVars !== "undefined" && window.myfico.globalVars !== null
        && typeof window.myfico.globalVars.returnUrl !== "undefined" && window.myfico.globalVars.returnUrl !== null && window.myfico.globalVars.returnUrl !== "") {
        url = encodeSSOservice(window.myfico.globalVars.returnUrl);

        //check for membercenter hash for redirect
        const hashval = window.location.hash;
        if (hashval && hashval.indexOf('#/verify') > -1) {
            const parseHash =  hashval.split("#");
            if (parseHash && typeof parseHash[2] !== "undefined") {
                url += "#" + parseHash[2];
            }
        } else {
            url += hashval;
        }
        
    } else {
        url = "https:" + properties.authServer + "/sso?service=https:" + properties.securewebserver + "/j_spring_cas_security_check";
    }

    return url;
    //try JSONP
    // var script = document.createElement('script');
    // script.src = url;
    // document.getElementsByTagName('head')[0].appendChild(script);    
}

export const encodeSSOservice = (url) => {
    let thisUrl = url;
    const serviceName = 'service=';

    if (typeof thisUrl !== "undefined" && thisUrl !== null) {
        if (thisUrl.indexOf(serviceName) > -1) {
            const splitUrl = thisUrl.split(serviceName);
            if (typeof splitUrl === 'object' && typeof splitUrl[0] === 'string' && typeof splitUrl[1] === 'string') {
                thisUrl = splitUrl[0] + serviceName + encodeURIComponent(splitUrl[1]);
            }
        }
    }
    return thisUrl
}

export const qsHash = () => {
    let hash = window.location.hash
    return qsCheck(hash);
}

export const qsSearch = () => {
    let search = window.location.search
    return qsCheck(search);
}

export const getQS = (name) => {
    const q = qsHash().get(name);
    return q;
};

export const qsCheck = (name) => {
    let val = new URLSearchParams();

    if (typeof name !== "undefined" && name !== null) {
        if (name.indexOf('?') > -1) {
            const splitVal = name.split('?');
            val = new URLSearchParams(splitVal[1]);
        }
    }
    return val
}

export const isFinra = () => {
    let val = false;
    const hashVal = qsHash().get('skin');
    const searchVal = qsSearch().get('skin');
    const returnUrlWFinra = qsSearch().get('returnUrl');

    if ((hashVal !== null && hashVal.toLowerCase() === 'finra')
        || (searchVal !== null && searchVal.toLowerCase() === 'finra')) {
        val = true;
    } else if (returnUrlWFinra !== null && returnUrlWFinra.toLowerCase().indexOf('skin=finra') > -1) {
        val = true;
    }
    return val;
}

export const isForum = () => {
    let val = false;
    const returnUrlWForum = qsSearch().get('returnUrl');

    if (returnUrlWForum !== null && returnUrlWForum.toLowerCase().indexOf('/forum-redirect') > -1) {
        val = true;
    }
    return val;
}

export const isLogin2svEnabled = () => {
    let val = false;

    if (typeof window.myfico !== "undefined" && window.myfico !== null
        && typeof window.myfico.globalVars !== "undefined" && window.myfico.globalVars !== null
        && typeof window.myfico.globalVars.l2sv !== "undefined" && window.myfico.globalVars.l2sv !== null && window.myfico.globalVars.l2sv !== ""
        && window.myfico.globalVars.l2sv === "true") {
        val = true;
    }

    return val;
};

export const scrollToTop = () => {
    if (window && typeof window.scrollTo === 'function') {
        window.scrollTo(0, 0);
    }
}


export const validate = {
    trim: function (str) {
        return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    },
    email: function (email) {
        var strRegExp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        email = validate.trim(email);

        if (email.length == 0)
            return 'You must enter your email address.';

        if (!strRegExp.test(email))
            return 'Invalid email address. Please enter your email address again.';

        return true;
    },
    ssn: function (ssnStr) {

        if (ssnStr) {
            const strRegExp = /^\d{3}-?\d{2}-?\d{4}$/;
            if (strRegExp.test(ssnStr)) {
                return true;
            }

            const letterRegChk = /[a-zA-Z]/g;

            if (ssnStr.length != 11 && !letterRegChk.test(ssnStr)) {
                return 'Please enter a complete SSN.'
            }
            return 'Invalid Social Security number.';
        }
        return "SSN is required."

    },
    dob: function (dobStr) {
        if (dobStr) {
            const dobRegex = /^\s*(1[012]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/((?:19|20)\d{2})\s*$/;
            if (dobRegex.test(dobStr)) {
                return true;
            }
            const letterRegChk = /[a-zA-Z]/g;
            if (dobStr.length !== 10 && !letterRegChk.test(dobStr)) {
                return `Please enter the date in "MMDDYYYY" format.`;
            }
            return "Invalid date of birth." 
        }
        return "DOB is required."
    },
    checkPassString: function (pasVal) {
        //this is the new password policy
        var capitalResult = false, lowerResultResult = false,
            specialResult = false, numberResult = false, digitResult = false;
        var count = 0;

        // checking lenght
        if (pasVal.length < 8 || pasVal.length > 128) {
            // $('#message-length').attr("validates", false);
            numberResult = false;
        } else {
            // $('#message-length').attr("validates", true);
            numberResult = true;
        }

        //empty state
        if (pasVal.length == 0) {
            return "Password is required"
        }

        //step 1
        //check for upper case
        if (/[A-Z]/.test(pasVal) == true) {
            capitalResult = true;
        }
        else {
            capitalResult = false;
        }
        //check for digit
        if (/[0-9]/.test(pasVal) == true) {
            digitResult = true;
        }
        else {
            digitResult = false;
        }
        //check for lower case
        if (/[a-z]/.test(pasVal) == true) {
            lowerResultResult = true;
        } else {
            lowerResultResult = false;
        }
        //check for supported special chars
        if (/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/.test(pasVal) == true && pasVal.length > 0) {
            specialResult = true;
        }
        else {
            specialResult = false;
        }

        //step2
        //Add the count: need at least 3 to pass
        if (capitalResult) {
            count += 1;
        }

        if (lowerResultResult) {
            count += 1;
        }

        if (digitResult) {
            count += 1;
        }

        if (specialResult) {
            count += 1;
        }

        //step 3
        if (count >= 3 && numberResult) {
            return true
        } else {
            return "New password must have at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character."
        }

    }

}
